import { Component, forwardRef, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DictionaryService } from '../../services/dictionary.service';
import { CheckAddressInterface } from '../../../domain/models/check-address.interface';
import { finalize } from 'rxjs/operators';
import { CheckAddressResponseInterface } from '../../../infrastructure/response/check-address-response.interface';
import { AbstractForm } from '../../../../core/application/forms/abstract.form';

@Component({
  selector: 'app-gst-control',
  templateUrl: './gst-control.component.html',
  styleUrls: ['./gst-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GstControlComponent),
      multi: true
    }
  ]
})
export class GstControlComponent implements OnDestroy {
  @Input() fb: AbstractForm;
  @Input() form: UntypedFormGroup;
  @Input() isRequired: boolean;
  @Input() isNotUnique: boolean;
  @Input() groupName?: string;
  disabled = false;
  value = '';
  addressCheckLoading: boolean;
  addressCheckSubscription: Subscription;
  isInit = true;

  constructor(private dictionaryService: DictionaryService) { }

  get isInvalid(): boolean {
    return this.form.get(this.getControlKey('gstNumber')).value &&
      this.form.get(this.getControlKey('gstNumber')).errors &&
      this.form.get(this.getControlKey('gstNumber')).invalid ||
      (this.fb.isSubmitted() && this.form.get(this.getControlKey('gstNumber')).invalid);
  }

  get isValid(): boolean {
    return this.form.get(this.getControlKey('gstNumber')).value &&
      this.form.get(this.getControlKey('gstNumber')).errors &&
      this.form.get(this.getControlKey('gstNumber')).valid ||
      (this.fb.isSubmitted() && this.form.get(this.getControlKey('gstNumber')).valid);
  }

  get placeholder(): string {
    let text = 'Enter GST';

    if (!this.isRequired) {
      text += ' (optional)'
    }

    return text;
  }

  ngOnDestroy(): void {
    this.addressCheckSubscription?.unsubscribe();
  }

  onChange: any = () => {/*empty*/};
  onTouched: any = () => {/*empty*/};

  writeValue(value: string): void {
    this.value = value;
    this.onChange(this.value);
    if (!this.isInit) {
      this.checkGst();
    }
    this.isInit = false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public getControlKey(name: string): string {
    return this.groupName ? `${this.groupName}.${name}` : name;
  }

  public checkGst(): void {
    if (this.form.get(this.getControlKey('pin')).invalid || this.form.get(this.getControlKey('gstNumber')).invalid) {
      return;
    }

    const payload = {
      pin: this.form.get(this.getControlKey('pin')).value,
      gst: this.form.get(this.getControlKey('gstNumber')).value,
      stateAbbr: this.form.get(this.getControlKey('state')).value,
    } as CheckAddressInterface;

    this.addressCheckLoading = true;

    this.addressCheckSubscription = this.dictionaryService.addressCheck(payload)
      .pipe(
        finalize(() => this.addressCheckLoading = false),
      )
      .subscribe((response) => {
        this.checkGstFormHandler(response);
      }, () => {/*empty*/});
  }

  private checkGstFormHandler(addressData: CheckAddressResponseInterface): void {
    switch (true) {
      case !addressData.gstValid:
        this.form.get(this.getControlKey('gstNumber')).setErrors({ gstInvalid: true });
        break;
      case !this.isNotUnique && !addressData.gstUnique:
        this.form.get(this.getControlKey('gstNumber')).setErrors({ gstNotUnique: true });
        break;
      case !addressData.gstBelongState:
        this.form.get(this.getControlKey('gstNumber')).setErrors({ gstNotBelongState: true });
        break;
    }
  }
}
