import { Component, OnInit } from '@angular/core';
import { UserCredential } from '@angular/fire/auth';
import { getString, RemoteConfig } from "@angular/fire/remote-config";
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IdTokenResult } from '@firebase/auth';
import { Subscription } from 'rxjs';
import { DEFAULT_ROUTE } from '../../../../../constants/default-route';
import { environment } from "../../../../../environments/environment";
import { FirebaseService } from '../../services/firebase.service';
import { CopyrightNotice } from '../../utils/copyright-notice';
import { AuthService } from '../../services/auth.service';
import { LoginForm } from '../../forms/login.form';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit
{
    fb: LoginForm;
    form: UntypedFormGroup;
    copyrightNotice: string = CopyrightNotice.fetch('text-white opacity-50');
    loading: Subscription | boolean;
    isRecoverForm: boolean;
    isRecovered: boolean;

    constructor(
        private authService: AuthService,
        private router: Router,
        private firebaseService: FirebaseService,
        private remoteConfig: RemoteConfig,
    )
    {}

    ngOnInit(): void
    {
        this.fb = new LoginForm();
        this.form = this.fb.getForm();
        this.authService.logout(false);
    }

    async tryLogin(userCredential: UserCredential)
    {
        try {
            this.loading = true;

            const response: IdTokenResult =  await userCredential.user.getIdTokenResult();
            const {token, expirationTime, claims} = response;
            if (!Number.isInteger(claims?.aid)) {
                this.form.setErrors({firebase: 'Registered user does not have administrator rights'});
                return;
            }
            this.authService.login(token, new Date(Date.parse(expirationTime)), this.form.value.email);

            if (claims?.accessGroup !== undefined) {
                const accessConfig = this.getAccessGroupConfig();
                const defaultRoute = accessConfig?.[claims?.accessGroup as string]?.routes?.[0] ?? DEFAULT_ROUTE.accessDenied;
                return await this.router.navigate([defaultRoute]);
            }

            this.router.navigate([this.authService.redirectUrl || DEFAULT_ROUTE.loggedIn]);
        } catch (e) {
            this.form.setErrors({firebase: this.firebaseService.getMessageFromCode(e.code) || e.message});
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    async tryLoginByEmail() {
        this.isRecovered = false;
        this.fb.markSubmitted();
        if (this.form.invalid) return;

        try {
            this.loading = true;
            const userCredential: UserCredential = await this.authService.signInEmailFirebase(this.form.value.email, this.form.value.password);
            return this.tryLogin(userCredential);
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    signInWithGoogle = async () => {
        try {
            this.loading = true;
            const userCredential: UserCredential = await this.authService.signInWithGoogle();
            return this.tryLogin(userCredential);
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    };

    public openRecoverForm(): void {
        this.isRecoverForm = true;
    }

    public openLoginForm(): void {
        this.isRecoverForm = false;
        this.isRecovered = false;
    }

    public setRecoverEmail($event: string): void {
        this.isRecovered = true;
        this.form.patchValue({
              email: $event,
          }
        );
    }

    get isProduction(): boolean {
        return environment.production;
    }

    private getAccessGroupConfig()
    {
        const dataString = getString(this.remoteConfig, 'access_group_config');
        if (!dataString) return;
        return JSON.parse(dataString);
    }
}
